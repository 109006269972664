import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import WebsitesWrapper from '../pages/websites/WebsitesWrapper'
import ListsPage from '../pages/lists/ListsPage'
import {ReceiveShipmentWrapper} from '../pages/fulfillment/accept-and-unpack/accept/ReceiveShipmentPage'
import {UnpackShipmentWrapper} from '../pages/fulfillment/accept-and-unpack/unpack/UnpackShipmentPage'
import FulfReceiversPage from '../pages/fulfillment/receivers/FulfReceiversPage'
import LocationsPage from '../pages/locations/LocationsPage'
import TemplatesPage from '../pages/email-templates/TemplatesPage'
import UserGroupsPage from '../pages/user-groups/UserGroupsPage'
import TracksPage from '../pages/tracks/TracksPage'
import EmployeesPage from '../pages/employees/EmployeesPage'
import ArticlesPage from '../pages/blog/ArticlesPage'
import CountriesPage from '../pages/countries/CountriesPage'
import OrdersPage from '../pages/orders/OrdersPage'
import WarehousesPage from '../pages/warehouses/WarehousesPage'
import AcceptTracksPage from '../pages/tracks-accept/AcceptTracksPage'
import TranslationsPage from '../pages/translations/TranslationsPage'
import TranslationBoardPage from '../pages/websites/elements/TranslationBoard/TranslationBoardPage'
import StoresPage from '../pages/stores/StoresPage'
import StoresCategoriesPage from '../pages/categories/stores/StoresCategoriesPage'
import BlogCategoriesPage from '../pages/categories/blog/BlogCategoriesPage'
import ShipmentsPage from '../pages/shipments/ShipmentsPage'
import ChatsPage from '../pages/chats/ChatsPage'
import EditingPage from '../pages/settings/editing-page/EditingPage'
import ParcelsPage from '../pages/parcels/ParcelsPage'
import DirectionsPage from '../pages/directions/DirectionsPage'
import CouriersPage from '../pages/couriers/CouriersPage'
import RegionsPage from '../pages/regions/RegionsPage'
import WebsitePage from '../pages/websites/website-page/WebsitePage'
import WebsitePages from '../pages/website-pages/WebsitePages'
import DeliveryTypesPage from '../pages/delivery-types/DeliveryTypesPage'
import PollsPage from '../pages/polls/PollsPage'
import FinancesPage from '../pages/finances/FinancesPage'

const PrivateRoutes = () => {
  // const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  // const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  // const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  // const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  // const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const UsersPage = lazy(() => import('../pages/users/UsersPage'))
  const GoodsPage = lazy(() => import('../pages/fulfillment/goods/GoodsPage'))
  // const GiftcardsListWrapper = lazy(() => import('../pages/payments/giftcards/list/GiftcardsList'))
  // const OtherPaymentsListWrapper = lazy(
  //   () => import('../pages/payments/other-payments/list/OtherPaymentsList')
  // )
  const WebsiteForms = lazy(() => import('../pages/website-forms/WebsiteForms'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registration */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route path='email-templates/*' element={<TemplatesPage />} />
        <Route path='polls/*' element={<PollsPage />} />
        <Route path='settings/edit/*' element={<EditingPage />} />
        {/* ----------------------- */}
        {/* BUYOUTS */}
        <Route
          path='buyouts/*'
          element={
            <SuspensedView>
              <OrdersPage />{' '}
            </SuspensedView>
          }
        />
        <Route
          path='buyouts/create'
          element={
            <Navigate
              to={`/buyouts/list`}
              state={{
                create: true,
              }}
            />
          }
        />
        {/* ----------------------- */}
        {/* TRACKS, ORDERS, SHIPMENTS, PARCELS */}
        <Route
          path='tracks/*'
          element={
            <SuspensedView>
              <TracksPage />{' '}
            </SuspensedView>
          }
        />
        <Route
          path='orders/*'
          element={
            <SuspensedView>
              <OrdersPage />{' '}
            </SuspensedView>
          }
        />
        <Route path='shipments/*' element={<ShipmentsPage />} />
        <Route path='parcels/*' element={<ParcelsPage />} />
        <Route path='accept-tracks/*' element={<AcceptTracksPage />} />
        {/* ----------------------- */}
        {/* FINANCES */}
        <Route path='finances/*' element={<FinancesPage />} />
        {/* ----------------------- */}
        {/* LOGISTICS */}
        <Route path='directions/*' element={<DirectionsPage />} />
        <Route path='states/*' element={<CountriesPage />} />
        <Route path='couriers/*' element={<CouriersPage />} />
        <Route path='areas/*' element={<RegionsPage />} />
        <Route path='locations/*' element={<LocationsPage />} />
        <Route path='delivery-types/*' element={<DeliveryTypesPage />} />
        <Route path='warehouses/*' element={<WarehousesPage />} />
        <Route path='translations/*' element={<TranslationsPage />} />
        {/* ----------------------- */}
        {/* USERS */}
        <Route
          path='user-groups/*'
          element={
            <SuspensedView>
              <UserGroupsPage />
            </SuspensedView>
          }
        />
        <Route
          path='employees/*'
          element={
            <SuspensedView>
              <EmployeesPage />
            </SuspensedView>
          }
        />
        <Route
          path='users/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        <Route
          path='receivers/*'
          element={
            <SuspensedView>
              <FulfReceiversPage />
            </SuspensedView>
          }
        />
        {/* ----------------------- */}
        {/* FULFILMENT */}
        <Route path='fulfillment/accept' element={<ReceiveShipmentWrapper />} />
        <Route path='fulfillment/unpack' element={<UnpackShipmentWrapper />} />
        <Route
          path='fulfillment/goods/*'
          element={
            <SuspensedView>
              <GoodsPage />
            </SuspensedView>
          }
        />
        {/* ----------------------- */}
        {/* WEBSITES */}
        <Route path='websites' element={<WebsitesWrapper />} />{' '}
        <Route path='websites/edit/*' element={<WebsitePage />} />
        <Route
          path='websites/translation/:id/*'
          element={
            <SuspensedView>
              <TranslationBoardPage />
            </SuspensedView>
          }
        />
        <Route
          path='websites/pages/*'
          element={
            <SuspensedView>
              <WebsitePages />
            </SuspensedView>
          }
        />
        {/* Lazy Modules */}
        <Route
          path='websites/forms/*'
          element={
            <SuspensedView>
              <WebsiteForms />
            </SuspensedView>
          }
        />
        {/* Lazy Modules End*/}
        {/* dynamic pages */}
        <Route
          path='blog/*'
          element={
            <SuspensedView>
              <ArticlesPage />{' '}
            </SuspensedView>
          }
        />
        <Route
          path='stores/*'
          element={
            <SuspensedView>
              <StoresPage />{' '}
            </SuspensedView>
          }
        />
        <Route
          path='website-categories/blog/*'
          element={
            <SuspensedView>
              <BlogCategoriesPage />{' '}
            </SuspensedView>
          }
        />
        <Route
          path='website-categories/stores/*'
          element={
            <SuspensedView>
              <StoresCategoriesPage />{' '}
            </SuspensedView>
          }
        />
        {/* ----------------------- */}
        {/* PAYMENTS */}
        {/* giftcards */}
        {/* <Route
          path='giftcards/list'
          element={
            <SuspensedView>
              <PageTitle>Список гифт-карт</PageTitle>

              <GiftcardsListWrapper empty={false} />
            </SuspensedView>
          }
        />
        <Route path='giftcards/add' element={<SuspensedView>add MAKE LAZY LOAD</SuspensedView>} />
        <Route
          path='giftcards/empty'
          element={
            <SuspensedView>
              <PageTitle>Список пустых гифт-карт</PageTitle>
              <GiftcardsListWrapper empty={true} />
            </SuspensedView>
          }
        /> */}
        {/* other payments */}
        {/* <Route
          path='other-payments/list'
          element={
            <SuspensedView>
              <PageTitle>Список других способов оплаты</PageTitle>
              <OtherPaymentsListWrapper />
            </SuspensedView>
          }
        />
        <Route
          path='other-payments/add'
          element={<SuspensedView>add payment MAKE LAZY LOAD</SuspensedView>}
        /> */}
        {/* ----------------------- */}
        {/* MSCLNS */}
        <Route
          path='chats/*'
          element={
            <SuspensedView>
              <ChatsPage />
            </SuspensedView>
          }
        />
        <Route
          path='lists/*'
          element={
            <SuspensedView>
              <ListsPage />
            </SuspensedView>
          }
        />
        {/* ----------------------- */}
        {/* ORIGINAL */}
        {/* <Route path='menu-test' element={<MenuTestPage />} /> */}
        {/* <Route path='builder' element={<BuilderPageWrapper />} />
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        /> */}
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--kt-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
