import {useQueryClient} from 'react-query'
import {QUERIES} from '../../../../helpers'
import {IWebsite} from '../../../../../app/pages/websites/core/_models'
import {AsideMenuItem} from '../AsideMenuItem'
import {AsideMenuItemWithSub} from '../AsideMenuItemWithSub'
import {FormattedMessage} from 'react-intl'

const WebsitesMenu = () => {
  const queryClient = useQueryClient()
  const websitesData: IWebsite[] = queryClient.getQueryData(QUERIES.WEBSITES_LIST) || []

  const menuItems = [
    {
      to: '/websites',
      icon: '/media/icons/duotune/communication/com001.svg',
      title: 'GENERAL.ASIDE_MENU.WEBSITES_LIST_TITLE',
      fontIcon: 'bi-layers',
    },
    {
      to: '/websites/translation',
      title: 'GENERAL.ASIDE_MENU.WEBSITES_TRANSLATION_TITLE',
      fontIcon: 'bi-sticky',
      icon: '/media/icons/duotune/maps/map004.svg',
      subItems: websitesData?.map((item) => ({
        key: item.id,
        to: `/websites/translation/${item.id}/list`,
        title: item.name || item.host,
        hasBullet: true,
      })),
    },
    {
      to: '/websites/forms',
      icon: '/media/icons/duotune/general/gen005.svg',
      title: 'GENERAL.ASIDE_MENU.WEBSITES_FORMS_TITLE',
      fontIcon: 'bi-sticky',
      subItems: websitesData
        ?.filter((item) => item.websiteForms.length > 0)
        .map((item) => ({
          key: item.id,
          to: `/websites/forms/list/${item.id}`,
          title: item.name || item.host,
          hasBullet: true,
        })),
    },
    {
      to: '/websites/pages',
      icon: '/media/icons/duotune/layouts/lay009.svg',
      title: 'GENERAL.ASIDE_MENU.WEBSITES_PAGES_TITLE',
      fontIcon: 'bi-sticky',
      subItems: websitesData
        ?.filter((item) => item.websitePages?.length > 0)
        .map((item) => ({
          key: item.id,
          to: `/websites/pages/list/${item.id}`,
          title: item.name || item.host,
          hasBullet: true,
        })),
    },
    {
      to: '/blog',
      icon: '/media/icons/duotune/art/art008.svg',
      title: 'GENERAL.ASIDE_MENU.WEBSITES_BLOG_TITLE',
      fontIcon: 'bi-layers',
    },
    {
      to: '/stores',
      icon: '/media/icons/duotune/ecommerce/ecm002.svg',
      title: 'GENERAL.ASIDE_MENU.WEBSITES_STORES_TITLE',
      fontIcon: 'bi-layers',
    },
    {
      to: '/website-categories',
      title: 'GENERAL.ASIDE_MENU.WEBSITES_CATEGORIES_TITLE',
      fontIcon: 'bi-sticky',
      icon: '/media/icons/duotune/ecommerce/ecm009.svg',
      subItems: [
        {
          to: `/website-categories/blog`,
          title: 'GENERAL.ASIDE_MENU.WEBSITES_BLOG_TITLE',
          hasBullet: true,
        },
        {
          to: `/website-categories/stores`,
          title: 'GENERAL.ASIDE_MENU.WEBSITES_STORES_TITLE',
          hasBullet: true,
        },
      ],
    },
  ]

  return (
    <>
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-uppercase fs-8 ls-1 text-gray-300 fw-bold'>
            <FormattedMessage id='GENERAL.ASIDE_MENU.WEBSITES_SECTION' />
          </span>
        </div>
      </div>

      {menuItems.map((item, index) =>
        item.subItems ? (
          <AsideMenuItemWithSub
            key={index}
            to={item.to}
            title={item.title}
            fontIcon={item.fontIcon}
            icon={item.icon}
          >
            {item.subItems.map((subItem, subIndex) => (
              <AsideMenuItem
                key={subIndex}
                to={subItem.to}
                title={subItem.title}
                hasBullet={subItem.hasBullet}
              />
            ))}
          </AsideMenuItemWithSub>
        ) : (
          <AsideMenuItem
            key={index}
            to={item.to}
            icon={item.icon}
            title={item.title}
            fontIcon={item.fontIcon}
          />
        )
      )}
    </>
  )
}

export default WebsitesMenu
