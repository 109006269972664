import {useQueryResponse} from '../../../core/QueryResponseProvider'
import StatisticsRow, {TChatStatRowProps} from './StatisticsRow'

const Statistics = () => {
  const {response} = useQueryResponse()

  const rows: TChatStatRowProps[] = [
    {
      cols: [
        {
          label: 'Відкриті чати',
          count: response?.openCount,
          color: 'danger',
          icon: '/media/icons/duotune/communication/com007.svg',
          addContClass: 'mb-5 me-7',
          link: '',
        },
        {
          label: 'Закриті чати',
          count: response?.closedCount,
          color: 'success',
          icon: '/media/icons/duotune/communication/com007.svg',
          addContClass: 'mb-5',
          link: '',
        },
      ],
    },
    {
      cols: [
        {
          label: 'Середній час першої відповіді',
          count: response?.firstReplyTimeAvg,
          color: 'warning',
          icon: '/media/icons/duotune/electronics/elc009.svg',
          addContClass: 'me-7',
          link: '',
          time: true,
        },
        {
          label: 'Середній час тривалості',
          count: response?.openTimeAvg,
          color: 'primary',
          icon: '/media/icons/duotune/general/gen013.svg',
          addContClass: '',
          link: '',
          time: true,
        },
      ],
    },
  ]
  return (
    <div className='mt-5'>
      {rows.map((row, index) => (
        <StatisticsRow row={row} key={index} />
      ))}
    </div>
  )
}

export default Statistics
