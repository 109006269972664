import {FormattedDate, FormattedTime} from 'react-intl'
import {toAbsoluteUrl} from '../../../../../../../_metronic/helpers'
import FlagsDirectionItem from '../../../../../../modules/custom/FlagsDirectionItem'
import {chatCategories, IChat} from '../../../../core/_models'

type Props = {
  chat: IChat
}

const ChatItemCell = ({chat}: Props) => {
  const utcCreatedAt = new Date(chat.createdAt)
  utcCreatedAt.setHours(utcCreatedAt.getHours() - 1)
  return (
    <>
      <div className='d-flex align-items-center mb-3'>
        <div className='symbol symbol-25px me-5'>
          <img
            src={toAbsoluteUrl(chatCategories[chat.subjectType])}
            alt=''
            style={{objectFit: 'contain'}}
          />
        </div>

        <p className='text-dark fw-bold text-hover-primary d-block fs-6 p-0 m-0'>
          {chat.title ? `#${chat.title}` : chat.fullTitle}
        </p>
      </div>

      <div className='d-flex align-items-center'>
        {chat.direction && (
          <ul className={`FlagsDirectionBar me-2 px-0 mb-0`}>
            <FlagsDirectionItem
              from={chat.direction?.countryFrom}
              to={chat.direction?.countryTo}
              minified={true}
            />
          </ul>
        )}
        <span className='fw-normal text-black d-block fs-7'>
          <FormattedDate year='numeric' month='numeric' day='numeric' value={utcCreatedAt} />,{' '}
          <FormattedTime value={utcCreatedAt} hour='numeric' minute='numeric' second='numeric' />
        </span>
      </div>
    </>
  )
}

export {ChatItemCell}
