/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {KTSVG} from '../../../../../../../_metronic/helpers'
import {statusColor} from '../../../../../../../_metronic/helpers/custom/funcs/statusColor'
import {Employee} from '../../../core/_models'

type Props = {
  employee: Employee
}

const EmployeeContacts: FC<Props> = ({employee}) => {
  return (
    <div className='d-flex align-items-center'>
      <div className='d-flex flex-column'>
        {!employee.emailConfirmed ? (
          <span className={`p-1 fw-normal ${statusColor(employee.emailConfirmed || false)}`}>
            {employee.email}
          </span>
        ) : (
          <span className='fw-normal'>{employee.email}</span>
        )}

        <div className='d-flex align-items-center'>
          {' '}
          {employee.hasTelegram && employee.phoneNumber && (
            <KTSVG path='/media/custom/svg/telegram-app.svg' className='svg-icon-3 me-2' />
          )}{' '}
          {!employee.phoneNumberTelegramConfirmed ? (
            <span
              className={`p-1 fw-normal StatusBar__status ${statusColor(
                employee.phoneNumberTelegramConfirmed || false
              )}`}
            >
              {employee.phoneNumber}
            </span>
          ) : (
            <span className='fw-normal'>{employee.phoneNumber}</span>
          )}
        </div>
      </div>
    </div>
  )
}

export {EmployeeContacts}
